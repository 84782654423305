<template>
  <BaseListPage locale-section="pages.artefacts" route="artefact">
    <ArtefactsList />
  </BaseListPage>
</template>

<script>
export default {
  name: "ArtefactsPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    ArtefactsList: () => import("./ArtefactsList")
  }
};
</script>
